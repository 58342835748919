import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerStatus } from "@core/enums/customer-status.enum";
import { UserGroup } from "@core/enums/e-user-groups";
import { GroupGuard } from "@core/guards/group.guard";
import { AuthGuard } from "@core/guards/auth.guard";
import { ReloadGuard } from '@core/guards/reload.guard';
import { ScopeGuard } from '@core/guards/scope.guard';
import { UserScope } from '@core/enums/e-user-scopes';

const routes: Routes = [
  { path : '' , redirectTo : '/customers' , pathMatch : 'full'},
  {
    path: 'customer/:id',
    loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule),
    canActivate: [AuthGuard, ScopeGuard],
    data: {
      accessScope: [UserScope.ADMIN_RW, UserScope.ADMIN_RO]
    }
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule),
    canActivate: [AuthGuard, ScopeGuard, ReloadGuard ],
    data: {
      accessScope: [UserScope.ADMIN_RW, UserScope.ADMIN_RO]
    }
  },
  {
    path: 'customers',
    loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
    canActivate: [AuthGuard, ScopeGuard],
    data: {
      accessScope: [UserScope.ADMIN_RW, UserScope.ADMIN_RO],
      customersStatus: CustomerStatus.ACTIVE,
    }
  },
  {
    path: 'deactivated-customers',
    loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
    canActivate: [AuthGuard, ScopeGuard],
    data: {
      accessScope: [UserScope.ADMIN_RW, UserScope.ADMIN_RO],
      customersStatus: CustomerStatus.DEACTIVATED,
    }
  },
  {
    path: 'uploadSupplemental',
    loadChildren: () => import('./uploadSupplemental/uploadSupplemental.module').then(m => m.UploadSupplementalModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      accessGroup: [UserGroup.ADMIN_TECH]
    }
  },
  {
    path: 'discoveries',
    loadChildren: () => import('./discoveries/discoveries.module').then(m => m.DiscoveriesModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      accessGroup: [UserGroup.ADMIN_TECH]
    }
  },
  {
    path: 'findings',
    loadChildren: () => import('./findings/findings.module').then(m => m.FindingsModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      accessGroup: [UserGroup.ADMIN_TECH]
    }
  },
  {
    path: 'questionnaire',
    loadChildren: () => import('./questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
    canActivate: [AuthGuard, ScopeGuard],
    data: {
      accessScope: [UserScope.ADMIN_RW, UserScope.ADMIN_RO]
    }
  },
  {
    path: 'rules',
    loadChildren: () => import('./rules/rules.module').then(m => m.RulesModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      accessGroup: [UserGroup.ADMIN_TECH]
    }
  },
  {
    path: 'srdiscussion',
    loadChildren: () => import('./srdiscussion/srdiscussion.module').then(m => m.SRDiscussionModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      accessGroup: [UserGroup.ADMIN_TECH]
    }
  },
  {
    path: 'applications',
    loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      accessGroup: [UserGroup.ADMIN_TECH]
    }
  },
  {
    path: 'quicklinks',
    loadChildren: () => import('./quicklinks/quicklinks.module').then(m => m.QuickLinksModule),
    canActivate: [AuthGuard, GroupGuard],
    data: {
      accessGroup: [UserGroup.ADMIN_TECH]
    }
  },
  {
    path: '**',
    redirectTo: 'customers'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
