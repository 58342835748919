<div class="dashboard-layout">
  <div *ngIf="!isMobileBreakpoint && !hideSidebar" class="dashboard-layout__sidebar">
    <ng-container *ngTemplateOutlet="sidebarContent"></ng-container>
  </div>

  <div *ngIf="isMobileBreakpoint && showMobileSidebar"
       class="dashboard-layout__sidebar--mobile"
       [@slide]
       slptBackdrop
       (onBackdropClick)="dismissSidebar()">

    <ng-container *ngTemplateOutlet="sidebarContent"></ng-container>

    <button class="close dashboard-layout__sidebar-close-button"
            [attr.aria-label]="'SLPT.CLOSE_SIDEBAR' | slptTranslate"
            (click)="dismissSidebar()"
            (keyup.enter)="dismissSidebar()">
      <slpt-icon [name]="'close'" [width]="'16px'" [height]="'16px'" fill="p1l"></slpt-icon>
    </button>
  </div>

  <ng-template #sidebarContent>
    <ng-content select=".dashboard-layout-sidebar"></ng-content>
  </ng-template>
  <div class="dashboard-layout__main"
       [class.dashboard-layout__main--full-page]="isMobileBreakpoint || hideSidebar">

    <header class="dashboard-layout__header">
      <div *ngIf="isMobileBreakpoint && !hideSidebar" class="dashboard-layout__main-menu-button-container">
        <slpt-icon-button class="dashboard-layout__main-menu-button"
                          [ariaLabel]="'SLPT.OPEN_SIDEBAR'"
                          [icon]="'menu'"
                          (click)="openSidebar()"
                          (keyup.enter)="openSidebar()">

        </slpt-icon-button>
		  </div>
      <div class="dashboard-layout__header-wrapper">
        <ng-content select=".dashboard-layout-header"></ng-content>
      </div>
    </header>

    <main class="dashboard-layout__main-wrapper">
      <ng-content select=".dashboard-layout-main"></ng-content>
    </main>
  </div>
</div>
