import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/l10n/translation';

import { HeaderComponent } from './header.component';



@NgModule({
  declarations: [HeaderComponent],
    imports: [
        CommonModule,
        TranslateModule
    ],
  exports: [HeaderComponent]
})
export class HeaderModule { }
