import { Directive, ElementRef, Input } from '@angular/core';
import { SvgIconService } from "@shared/directives/svg-icon/svg-icon.service";

@Directive({
  selector: '[prtlSvgIcon], prtl-svg-icon',
})
export class SvgIconDirective {
  private iconName;

  @Input()
  public set icon(value: string) {
    if (!value || this.iconName === value) {
      return;
    }
    this.iconName = value;
    this.iconService.getIcon(value).subscribe(
      icon => {
        if(!icon){
          console.warn('no icon with name: ', value)
          return;
        }
        this.el.nativeElement.innerHTML = icon;
      }
    );
  }

  constructor(private el: ElementRef, private iconService: SvgIconService) {
  }

}
