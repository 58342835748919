import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { awsmobile } from 'src/aws-exports';
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private storagePrefix=`CognitoIdentityServiceProvider.${awsmobile.Auth.Cognito.userPoolClientId}`;

  public getToken(): any {
    return localStorage.getItem(`${this.storagePrefix}.${this.getUserName()}.accessToken`);
  }

  public getUserName(): string {
    return localStorage.getItem(`${this.storagePrefix}.LastAuthUser`);
  }

  public getDecodedToken() {
    const decodedToken: any = jwtDecode(this.getToken());

    if (decodedToken['scope']) {
      decodedToken.scopes=decodedToken['scope'].split(' ');
    }
    return decodedToken;
  }

}
