<div class="sidebar">
  <img class="sidebar__logo" src="../../../../assets/images/logo-icon.svg" alt="logo"/>

  <div class="user" *ngIf="userInfo">
    <div *ngIf="userInfo?.initials" class="user-avatar">
      <h4 (click)="signOut()">{{ userInfo?.initials }}</h4>
    </div>
    <h3 *ngIf="userInfo?.name" class="user__name">
      {{ userInfo?.name }}
    </h3>
    <h4 class="user__email">
      {{ userInfo?.email }}
    </h4>
    <h6 class="user__role">
      {{ userInfo?.roles }}
    </h6>
  </div>

  <div class="routes-wrapper">
    <ng-container *ngFor="let routerItem of routerItems | groupFilter: userInfo?.groups">
      <div [routerLink]="routerItem.path"
           routerLinkActive="active"
           class="routes-item">
        <div class="icon-wrapper">
            <span class="icon"
                  prtlSvgIcon
                  [icon]="routerItem?.icon">
            </span>
        </div>
        <h3 class="routes-item__title">
          {{ routerItem.title | slptTranslate }}
        </h3>
      </div>
    </ng-container>
  </div>
</div>
