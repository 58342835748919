import { UserGroup } from "@core/enums/e-user-groups";
import { UserScope } from "@core/enums/e-user-scopes";

interface AuthUser {
  email: string,
  given_name?: string,
  family_name?: string,
  scopes: string[],
  groups: string[]
}

export interface UserInfo {
  initials: string,
  name?: string,
  email: string,
  roles: string,
  groups: string[]
}

export default class UserInfoAdapter {
  public static transform(authUser: AuthUser): UserInfo {
    if (!authUser) {
      return null;
    }

    if (authUser?.given_name && authUser?.family_name) {
      return {
        initials: UserInfoAdapter.prepareInitialsFromName(authUser),
        name: `${authUser.given_name} ${authUser.family_name}`,
        email: authUser?.email,
        roles: UserInfoAdapter.prepareRoles(authUser.scopes, authUser['cognito:groups']),
        groups: authUser['cognito:groups']
      };
    }
    
    return {
      initials: UserInfoAdapter.prepareInitialsFromEmail(authUser),
      email: authUser.email,
      roles: UserInfoAdapter.prepareRoles(authUser.scopes, authUser['cognito:groups']),
      groups: authUser['cognito:groups']
    };
  }

  private static prepareInitialsFromName(authUser: AuthUser): string {
    return (authUser.given_name[0] + authUser.family_name[0]).toUpperCase();
  }

  private static prepareInitialsFromEmail(authUser: AuthUser): string {
    if (!authUser?.email) {
      return ''
    }
    return authUser.email.split('@')[0].split('.').reduce((acc, item) => acc + item[0].toUpperCase(), '');
  }

  private static prepareRoles(scopes: string[], groups: string[]): string {
    let labels = [];
    scopes?.forEach(scope => {
      const displayRole = UserInfoAdapter.getDisplayRole(scope);
      if (displayRole) {
        labels.push(displayRole);
      }
    });
    groups?.forEach(group => {
      const displayRole = UserInfoAdapter.getDisplayGroup(group);
      if (displayRole) {
        labels.push(displayRole);
      }
    });
    return labels.join(', ');
  }

  private static getDisplayRole(scope: string): string {
    switch (scope) {
      case UserScope.ADMIN_RW:
        return 'Admin RW';
      case UserScope.ADMIN_RO:
        return 'Admin RO'
      default:
        return null;
    }
  }

  private static getDisplayGroup(group: string): string {
    switch (group) {
      case UserGroup.ADMIN_COORDINATOR:
        return 'Migration Coordinator';
      case UserGroup.ADMIN_TECH:
        return 'Migration SE'
      default:
        return '';
    }
  }

}
