import { Pipe, PipeTransform } from '@angular/core';
import { RouterItem } from '@core/interfaces/router-item.interface';

@Pipe({
  name: 'groupFilter'
})
export class GroupFilter implements PipeTransform {
  transform( routeritems: RouterItem[], groups: string[] ): any {
    return routeritems.filter( item => !item.groupToAccess || (groups && groups.includes( item.groupToAccess ) ) );
  }
}