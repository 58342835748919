import { Injectable } from '@angular/core';

import { AlertService } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/components/alert';
import { AlertConfig } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/components/alert/alert.model';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  public alertConfig: AlertConfig = {
    align: 'top',
    duration: 3000
  };

  constructor(
    private alertService: AlertService
  ) {
  }

  public successMessage(message: string): void {
    this.alertConfig.icon = 'complete';
    this.alertConfig.color = 's2l';
    this.alertConfig.title = {
      translateKey: 'NOTIFICATIONS.TITLES.SUCCESS'
    };
    this.alertConfig.message = {
      translateKey: message
    };

    this.alertService.open(this.alertConfig).then();
  }

  public errorMessage(message: string): void {
    this.alertConfig.icon = 'error';
    this.alertConfig.color = 's4l';
    this.alertConfig.title = {
      translateKey: 'NOTIFICATIONS.TITLES.ERROR'
    };
    this.alertConfig.message = {
      translateKey: message
    };

    this.alertService.open(this.alertConfig).then();
  }

  public dismiss(): void {
    this.alertService.destroy();
  }
}
