import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SvgIconService {
  private readonly iconPath = '/assets/icons/';
  private iconCache = {};

  constructor(private http: HttpClient) {
  }

  public getIcon(iconName: string): Observable<string | null> {
    if(this.iconCache?.[iconName]) {
      return of(this.iconCache?.[iconName]);
    }

    const path = this.iconPath + iconName + '.svg'
    return this.http.get(path, { responseType: 'text' }).pipe(
      tap(icon => {
        if(icon) {
          this.iconCache[iconName] = icon;
        }
        catchError(() => of(null))
      })
    )
  }
}
