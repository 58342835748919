import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/l10n/translation';

import { SidebarComponent } from './sidebar.component';
import { SvgIconModule } from '@shared/directives/svg-icon/svg-icon.module';
import { GroupFilter } from '@core/pipes/groupfilter.pipe';

@NgModule({
  declarations: [
    GroupFilter,
    SidebarComponent
  ],
  imports: [      
      CommonModule,
      RouterModule,
      SvgIconModule,
      TranslateModule
  ],
  exports: [
    SidebarComponent
  ]
})
export class SidebarModule { }
