import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { StateService } from '@core/services/state.service';

@Injectable({
  providedIn: 'root'
})
export class ReloadGuard  {
  constructor(
    private stateService: StateService,
    private router: Router
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    if ( !this.stateService.getSelectedCustomer() ) {
      console.log( 'no selected customer!' );
      this.router.navigateByUrl( '/customers' );
      return false;
    }

    return true;

  }
}