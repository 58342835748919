/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import { environment } from './environments/environment';

export const awsmobile = {
  Auth: {
    Cognito: {
      userPoolClientId: environment.client_id,
      userPoolId: 'us-east-1_ObVviVEAC',
      loginWith: {
        oauth: {
          scopes: [ 'email','openid','profile'],
          domain: 'auth.saas-upgrade.sailpointtechnologies.com',
          redirectSignIn: [ environment.redirectSignIn ],
          redirectSignOut: [ environment.redirectSignOut ],
          responseType: 'code' as 'code' | 'token',
        },
      }
    }
  }
};
