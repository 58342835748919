import { RouterItem } from "../interfaces/router-item.interface";

export const routerSidebarItems: RouterItem[] = [
  {
    title: 'SIDEBAR.CUSTOMERS',
    path: '/customers',
    icon: 'users',
  },
  {
    title: 'SIDEBAR.DEACTIVATED_CUSTOMERS',
    path: '/deactivated-customers',
    icon: 'deactivated-customer',
  },
  {
    title: 'SIDEBAR.RULES',
    path: '/rules',
    icon: 'rules',
    groupToAccess: 'admin_tech'
  },
  {
    title: 'SIDEBAR.SRDISCUSSION',
    path: '/srdiscussion',
    icon: 'srdiscussion',
    groupToAccess: 'admin_tech'
  }
];
