import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { WindowRef } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/util/window-ref';


const WINDOW_MOBILE_BREAKPOINT = 1200;

@Component({
  selector: 'prtl-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardLayoutComponent implements OnInit {
  @Input() public breadcrumbsBkgColor: 'custom' | 'p1' | 'transparent' = 'p1';
  @Input() public hideSidebar = false;

  public isMobileBreakpoint = false;
  public showMobileSidebar = false;

  constructor(private windowRef: WindowRef) {
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number): void {
    this.isMobileBreakpoint = width < WINDOW_MOBILE_BREAKPOINT;
  }

  ngOnInit(): void {
    this.isMobileBreakpoint = this.windowRef.nativeWindow.innerWidth < WINDOW_MOBILE_BREAKPOINT;
  }

  public dismissSidebar(): void {
    this.showMobileSidebar = false;
  }

  public openSidebar(): void {
    this.showMobileSidebar = true;
  }
}
