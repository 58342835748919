import { Component, OnInit, Self } from '@angular/core';
import { UserService } from '@core/services/user.service';
import { NgOnDestroy } from '@core/services/unsubscribe.service';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { RouterItem } from "@core/interfaces/router-item.interface";
import { routerSidebarItems } from '@core/configs/router-sidebar-items';
import Utils from "@utils/utils";
import UserInfoAdapter, { UserInfo } from "@shared/layouts/sidebar/user-info-adapter";
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'prtl-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [AuthService,NgOnDestroy]
})
export class SidebarComponent implements OnInit {
  public routerItems: RouterItem[] = routerSidebarItems;
  public userInfo: UserInfo;

  constructor(
    @Self() private readonly ngOnDestroy$: NgOnDestroy,
    private userService: UserService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.listenAuthUserChanges();
  }

  private listenAuthUserChanges(): void {
    this.userService.getAuthUser$().pipe(
      distinctUntilChanged(Utils.isEqual),
      map(UserInfoAdapter.transform),
      takeUntil(this.ngOnDestroy$),
    ).subscribe((userInfo: any) => {
      this.userInfo = userInfo;
    });
  }

  public signOut() {
    console.log('logout!');
    this.authService.logout();
  }
}
