import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BackdropModule } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/components/backdrop';
import { ButtonModule } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/components/button';
import { IconCloseModule, IconMenuModule } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/components/icons';
import { DashboardLayoutComponent } from './dashboard-layout.component';
import { IconModule } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/components/icons';
import { TranslateModule } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/l10n/translation';

@NgModule({
  declarations: [DashboardLayoutComponent],
  imports: [
    CommonModule,
    BackdropModule,
    ButtonModule,
    IconCloseModule,
    IconMenuModule,
    IconModule,
    TranslateModule,
    BackdropModule
  ],
  exports: [DashboardLayoutComponent]
})
export class DashboardLayoutModule {
}
