import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotifyService } from '../services/notify.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private notifyService: NotifyService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401) {
          this.notifyError(error);
        }
        return throwError(error);
      })
    );
  }

  private notifyError(error: HttpErrorResponse): void {
    const message = this.prepareErrorMessage(error);
    this.notifyService.errorMessage(message);
  }

  private prepareErrorMessage(error: HttpErrorResponse): string {
    const message = error?.error?.message;

    if (!message) {
      return 'NOTIFICATIONS.MESSAGES.SOMETHING_WENT_WRONG';
    }

    if(Array.isArray(message)) {
      return  message.join('\n')
    }

    return message;
  }
}
