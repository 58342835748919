import { Injectable } from '@angular/core';
import { Customer } from "@core/interfaces/customer.interface";
import { CustomersService } from './customers.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor( ) { 
  }

  private customer:Customer;

  public setSelectedCustomer( customer:Customer ):void {
    this.customer = customer;
  }

  public getSelectedCustomer():Customer {
    return this.customer;
  }
}


