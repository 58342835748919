import { Component } from '@angular/core';

import { Amplify } from 'aws-amplify';

import { awsmobile } from '../aws-exports';

Amplify.configure(awsmobile);

@Component({
  selector: 'prtl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'web-ui-portal';
  loaderConfig = {
    register: 'LoadingMask1'
  };

}
