import _isEqual from 'lodash.isequal';

export default class Utils {

  public static isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  public static isObject(obj: any): boolean {
    return typeof obj === 'object';
  }

  public static isEqual(value: any, other: any): boolean {
    return _isEqual(value, other);
  }
}
