import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private authUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public setAuthUser(authUser: any): void {
    this.authUser.next(authUser);
  }

  public getAuthUser$(): Observable<any> {
    return this.authUser.asObservable();
  }

  public getAuthUser(): any {
    return this.authUser.value;
  }
}
