import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, from } from 'rxjs';
import { catchError, filter, switchMap, take, finalize, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { TokenService } from "@core/services/token.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isAuthRequest(req)) {
      return next.handle(req);
    }

    const token = this.tokenService.getToken();

    if (token) {
      req = this.addToken(req, token);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handle401Error(req, next);
        }
        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(this.authService.refreshToken()).pipe(
        switchMap(() => {
          this.isRefreshing = false;
          const newToken = this.tokenService.getToken();
          this.refreshTokenSubject.next(newToken);
          console.log(`Token Refreshed. New Expiry: ${this.tokenExpiry()}`);
          return next.handle(this.addToken(request, newToken));
        }),
        catchError((error) => {
          console.log('Token refresh failed:', error);
          this.isRefreshing = false;
          this.refreshTokenSubject.next(null);
          if (error.status === 401) {
            this.authService.logout();
          }
          return throwError(() => error);
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token => {
        return next.handle(this.addToken(request, token));
      })
    );
  }

  private isAuthRequest(req: HttpRequest<any>): boolean {
    const authUrls = ['/auth/login', '/auth/refresh'];
    return authUrls.some(url => req.url.includes(url));
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private tokenExpiry(): string {
    let exp = this.tokenService.getDecodedToken().exp;
    let date = new Date(exp);
    return `${date.getHours()}:${date.getMinutes}:${date.getSeconds()}`;
  }
}