import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from "@core/services/auth.service";
import { NotifyService } from "@core/services/notify.service";

@Injectable({
  providedIn: 'root'
})
export class ScopeGuard  {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private notifyService: NotifyService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!route?.data?.accessScope) {
      console.warn('Configure router access scopes!')
      return true;
    }

    return this.userService.getAuthUser$().pipe(
      filter(Boolean),
      take(1),
      map((authUser: any) => {
        if (!authUser) {
          return false;
        }

        if (this.checkAccessScopes(authUser?.scope, route.data.accessScope)) {
          return true;
        }

        this.notifyService.errorMessage(`NOTIFICATIONS.MESSAGES.NO_AUTH_ROLE`);
        return false;
      })
    );
  }


  private checkAccessScopes(scopes: string[], accessScopes: string | string[]): boolean {
    if (!scopes) {
      return false;
    }

    if (Array.isArray(accessScopes)) {
      return !!accessScopes.find(accessScope => this.checkAccessScope(scopes, accessScope));
    }

    return this.checkAccessScope(scopes, accessScopes)
  }

  private checkAccessScope(scopes: string[], accessScope: string): boolean {
    return scopes.includes(accessScope);
  }
}
