export const environment = {
  production: true,
  redirectSignIn: 'https://admin.dev.saas-upgrade.sailpointtechnologies.com',
  redirectSignOut: 'https://admin.dev.saas-upgrade.sailpointtechnologies.com',
  requestUrl: 'https://api.dev.saas-upgrade.sailpointtechnologies.com/',
  assessmentUrl: 'https://api.assessor.dev.saas-upgrade.sailpointtechnologies.com',
  client_id: '1690ghpofsi11tvgp5p0bfj44o',
  customerLoginLink: 'https://dev.saas-upgrade.sailpointtechnologies.com/?access-token='
  // customerLoginLink: 'https://saas-upgrade.sailpointtechnologies.com?access-token='
};
